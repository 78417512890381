import React, { Component, Suspense } from "react";

const NavbarPage = React.lazy(() =>
  import("../../components/Navbar/Navbar_Page")
);
const Section = React.lazy(() => import("./section"));
const About = React.lazy(() => import("../../components/About/about"));
const Process = React.lazy(() => import("../../components/Process/process"));
const Portfolio = React.lazy(() =>
  import("../../components/Portfolio/portfolio")
);
const Counter = React.lazy(() => import("../../components/Counter/counter"));
// const OurTeam = React.lazy(() => import('../../components/Team/our-team'));
const Footer = React.lazy(() => import("../../components/Footer/footer"));
const Testimonials = React.lazy(() =>
  import("../../components/Testimonials/testimonials")
);
const Pricing = React.lazy(() => import("../../components/Pricing/pricing"));
const Blog = React.lazy(() => import("../../components/Blog/blog"));
// const Cta = React.lazy(() => import('../../components/Cta/Cta'));
const GetInTouch = React.lazy(() =>
  import("../../components/GetInTouch/GetInTouch")
);
// const BookingForm = React.lazy(() => import('../../components/BookingForm/BookingForm'));
const PopupModal = React.lazy(() =>
  import("../../components/PopupModal/popup-modal.js")
);
const BogoActivation = React.lazy(() =>
  import("../../components/BogoActivation/bogo-activation")
);

class Index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "about", navheading: "About" },
        { id: 5, idnm: "pricing", navheading: "Pricing" },
        { id: 3, idnm: "portfolio", navheading: "Cities" },
        // { id: 4 , idnm : "team", navheading: "Team" },

        { id: 6, idnm: "testimonial", navheading: "Testimonial" },
        // { id: 7 , idnm : "bookingform", navheading: "Book Now" },
        // { id: 7 , idnm : "blog", navheading: "Blog" },
        { id: 8, idnm: "contact", navheading: "Contact" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: true });
    }
  };

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">Loading...</div>
        </div>
      </div>
    );
  };

  render() {
    const scrollToTop = () => {
      if (document.documentElement.scrollHeight > 4000) {
        window.scrollTo({
          top: 1200,

          behavior: "smooth",
          /* you can also use 'auto' behaviour
                 in place of 'smooth' */
        });
      }

      if (document.documentElement.scrollHeight > 6000) {
        window.scrollTo({
          top: 2250,

          behavior: "smooth",
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      }

      if (document.documentElement.scrollHeight > 8000) {
        window.scrollTo({
          top: 1500,

          behavior: "smooth",
          /* you can also use 'auto' behaviour
         in place of 'smooth' */
        });
      }
    };
    //get current URL
    const currentUrl = window.location.href;

    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          {/* Importing Navbar */}
          <NavbarPage
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          {/* Importing section */}
          <Section />

          {/* Importing blog */}
          <Blog />

          {/* Importing about us */}
          <About />

          {/* Importing process */}
          <Process />

          <BogoActivation />

          {/* Importing pricing */}
          <Pricing />

          {/* Importing portfolio */}
          <Portfolio />

          {/* Importing counter */}
          {/* <Counter/> */}

          {/* Importing team */}
          {/* <OurTeam/> */}

          {/* Importing testimonials */}
          <Testimonials />

          {/* Importing cta */}
          {/* <Cta/> */}

          {/* Importing Booking form */}
          {/* <BookingForm/> */}

          {/* Importing Popup Modal */}
          {currentUrl == "https://starclubcard.info/book-now/" ? (
            <PopupModal />
          ) : null}

          {/* Importing get in touch */}
          <GetInTouch />

          {/* Importing footer */}
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Index1;
